import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BCCDemoContact from "../components/BCCDemoContact";

const DemoPage = () => (
    <Layout>
        <SEO title="Schedule a Demo" />
        <section className="hero is-primary is-fullheight-with-navbar">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column is-half">
                            <h1 className="title has-text-warning">We&apos;d love to give you a demo!</h1>
                            <h2 className="subtitle">Provide your contact info and we will be in touch.</h2>
                            <p>
                                We&apos;ll create an account for you on{" "}
                                <a className="has-text-warning" href="https://bcc2020.galaxyworks.io/initial/galaxy">
                                    https://bcc2020.galaxyworks.io
                                </a>{" "}
                                and, if you&apos;re interested, we would also love to give you a tour.
                            </p>
                        </div>
                        <div className="column">
                            <div className="container has-text-centered">
                                <BCCDemoContact />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default DemoPage;
