import React from "react";

export default class BCCDemoContact extends React.Component {
    render() {
        return (
            <React.Fragment>
                <form
                    name="demo"
                    method="POST"
                    action="/contact_confirm"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                >
                    <input type="hidden" name="form-name" value="demo" />
                    <p className="is-hidden">
                        <label>
                            Do not fill this out if you are human: <input name="bot-field" />
                        </label>
                    </p>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label>Your Name</label>
                        </div>
                        <div className="field-body control">
                            <input className="input" type="text" name="name" />
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label>Email Address</label>
                        </div>
                        <div className="field-body control">
                            <input className="input" type="email" name="email" />
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label"></div>
                        <div className="control field-body">
                            <button className="button is-light" type="submit">
                                Reach Out
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}
